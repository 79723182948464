import React from 'react';

const APIDoc = () => {
  return (
    <div>
      <iframe
        src="https://staging.broadbased.net/userModule/proxy/apidoc"
        title="API Documentation"
        style={{ width: '100%', height: '600px', border: 'none' }}
      >
        Your browser does not support iframes. Click <a href="https://staging.broadbased.net/userModule/proxy/apidoc">here</a> to view the API documentation.
      </iframe>
    </div>
  );
};

export default APIDoc;
